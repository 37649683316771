import React, { useContext } from 'react';
import { ThemeContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Anchor from "ToolboxComponents/commons/anchors/anchor/anchor";
import PropTypes from 'prop-types';

import { PrivacyViewWrapper } from 'ToolboxComponents/webapp/pages/privacy-view/privacy-view.styles';

const PrivacyView = ( {infos} ) => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const { owner, email } = infos;
  return (
    <>
      {language === 'fr' ?
        <PrivacyViewWrapper theme={theme}>
          <h2>Politique de confidentialité</h2>

          <br />

          <p>
            <strong>Le respect de la vie privée des utilisateurs est un enjeu important pour {owner}, qui collecte et traite les données à caractère personnel de ses utilisateurs de manière respectueuse et responsable.</strong>
            Une donnée à caractère personnel est une information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres.
            La protection des données personnelles est essentielle et {owner} met tout en œuvre pour respecter la règlementation de protection des données à caractère personnel.
            Tous les traitements de données à caractère personnel ont fait l’objet d’une déclaration à la Commission Nationale de l’Informatique et des Libertés (Cnil).
          </p>

          <br />

          <p>
            Pour toute information sur la protection des données à caractère personnel, l’Utilisateur peut également consulter le site de la Cnil : <a target="_blank" href="https://www.cnil.fr">www.cnil.fr</a>
          </p>

          <br />

          <h3>Données collectées et finalité de leur utilisation</h3>
          <p>
            Lors de sa navigation sur la Webapp et/ou sur l’application mobile, l’Utilisateur est informé que des données relatives à sa connexion et à sa navigation sont traitées par {owner} et l’administrateur de la Plateforme, notamment par le biais de cookies et des formulaires qu’ils ont remplis.
            A titre d’exemple, en créant son Compte, l’Utilisateur complète des formulaires et indique des informations sur son identité, sa situation familiale, ses coordonnées, sa qualité, sa photographie, ses envies, sa géolocalisation et le type de transport utilisé. Les informations avec un astérisque sont obligatoires pour pouvoir créer un Compte.
            Ces informations sont purement déclaratives et l’Utilisateur est seul responsable de la qualité de leur saisie.
            En d’autres termes, aucune vérification n’est opérée par {owner}.
            Par ailleurs les données collectées par {owner} lors de la navigation de l’Utilisateur sont utilisées pour améliorer son expérience et plus particulièrement traiter les créations de compte, la saisie de données, le bon fonctionnement de la Webapp et/ou de l’application mobile, la réalisation de statistiques et un profilage non nominatif.
          </p>

          <br />

          <h3>Moyens de collecte</h3>
          <p>
            La collecte des données à caractère personnel est effectuée lors de la navigation par l’intermédiaire de cookies et de formulaires tels que les formulaires de création de profils, d’inscription à la newsletter ou encore lorsque l’utilisateur contacte la conciergerie {owner} que ce soit par email, téléphone, chat ou SMS.
          </p>

          <br />

          <p>
            {owner} ne dépose des cookies que si le navigateur de l’utilisateur le permet et à condition que l’utilisateur ait poursuivi sa navigation.
            Les cookies peuvent être déposés sur le terminal de l’utilisateur (ordinateur, Smartphone, tablette).
            Les utilisateurs disposent de la possibilité de paramétrer leur navigateur afin de refuser les cookies.
            Le blocage de certains cookies peut toutefois perturber le fonctionnement des services proposés.
          </p>

          <br />

          <p>
            Lors de sa navigation sur la Webapp et/ou sur l’application mobile, l’Utilisateur est informé que des données relatives à sa connexion et à sa navigation sont traitées par {owner}, notamment par le biais de cookies.
          </p>

          <br />

          <p>
            Un cookie est un petit fichier déposé sur l’ordinateur de l’Utilisateur pour une durée limitée lorsque celui-ci navigue sur un site.
            Les informations liées à la navigation sont alors stockées à cet endroit.
            Ces cookies permettent donc d’identifier l’Utilisateur à chacune de ses visites et ainsi aident à l'amélioration de l’expérience utilisateur en proposant à celui-ci des services encore plus appropriés à ses besoins.
          </p>

          <br />

          <p className="font-weight-bold">
            Aucune publicité n'est soumise à nos utilisateurs sur notre plateforme, les cookies déposés n'ont donc pas pour objectif de leur proposer une quelconque publicité sur notre site.
          </p>

          <br />

          <p>Pour plus d’information, consultez notre politique de <Anchor code='cookies'>cookies</Anchor>.</p>

          <br />

          <h3>Destinataires</h3>

          <p>
            Les données des Utilisateurs sont conservées confidentiellement par {owner}, sont exclusivement traitées par {owner} et, sauf opposition de leur part, ses partenaires.
          </p>

          <br />

          <h3>Droit des personnes sur leurs données</h3>

          <p>
            Conformément aux dispositions légales relatives à la protection des données à caractère personnel, l’Utilisateur dispose notamment d'un droit d'accès, de rectification et d'opposition pour motif légitime s'agissant des informations le concernant et du droit d’organiser le sort de ses données post-mortem.
            Ces droits s’exercent par email à <a href={`mailto:${email}`}>{email}</a> en précisant dans l’objet « MES DONNEES » et en joignant la copie d’un justificatif d’identité.
          </p>

          <br />

          <h3>Durée de conservation des données et accès par l'utilisateur pour supprimer ses données</h3>

          <p>
            Les données ne sont conservées que pour la durée nécessaire aux finalités de leur collecte et de leur traitement.
          </p>

          <br />

          <p>
            Les Utilisateurs peuvent à tout moment se désinscrire et supprimer leurs données en accédant à leur compte et leurs données sont conservées le temps de la relation contractuelle.
            Au-delà, les données ne pourront être conservées que pour établir la preuve d’un droit ou d’un contrat, ou au titre du respect d’une obligation légale et conformément à la réglementation en vigueur. Les informations collectées par les cookies seront quant à elles conservées pendant treize (13) mois, à l’issue de cette période ces données seront supprimées.
          </p>

          <br />

          <p>
            La suppression des données de l'utilisateur peut être fait à tout moment en bas de son profil à la mention "Je souhaite supprimer mon compte et les données associées".
          </p>

          <br />

          <h3>Sécurité et confidentialité</h3>

          <p>
            Soucieux de garantir un niveau de sécurité adapté à ses utilisateurs, {owner} met tout en œuvre pour préserver la sécurité des données traitées.
            A titre d’exemple, la Webapp et/ou sur l’application mobile est hébergé sur des serveurs basés en France par 2ISR et ne transfère aucune donnée en dehors de l’Union européenne.
            Les données sont conservées de manière confidentielle et conformément aux déclarations auprès de la Cnil.
          </p>
      </PrivacyViewWrapper> 
      :
      <PrivacyViewWrapper theme={theme}>
            <h2>Privacy Policy</h2>

            <br />

            <p>
              <strong>Respect for the privacy of Users is an important issue for {owner}, which collects and processes the personal data of its Users in a respectful and responsible manner.</strong>
               Personal data is information relating to a natural person identified or who can be identified, directly or indirectly, by reference to an identification number or to one or more elements specific to them. The protection of personal data is essential and {owner} makes every effort to comply with the regulations on the protection of personal data. All processing of personal data has been declared to the Commission Nationale de l'Informatique et des Libertés (CNIL).
            </p>

            <br />

            <p>
              For any information on the protection of personal data, the User may also consult the CNIL website:  <a target="_blank" href="https://www.cnil.fr">www.cnil.fr</a>
            </p>

            <br />

            <h3>Data collected and purpose of its use</h3>
            <p>
              When browsing the Webapp and/or the mobile application, the User is informed that data relating to their connection and browsing is processed by {owner} and the administrator of the Platform, in particular through cookies and the forms they have filled in. For example, by creating their Account, the User completes forms and indicates information on their identity, family situation, contact details, title, photograph, desires,  geolocation and the type of transport used. Information with an asterisk is mandatory to create an Account. This information is purely declarative and the User is solely responsible for the quality of its entry. In other words, {owner} does not check this information. The data collected by {owner} while the User is browsing is used to improve their experience and more particularly to process account creations, data entry, the smooth operation of the Webapp and / or mobile application, the production of statistics and non-nominative profiling.
            </p>

            <br />

            <h3>Collection means</h3>
            <p>
              The collection of personal data is carried out during browsing via cookies and forms such as forms for creating profiles, subscribing to the newsletter or when the User contacts the {owner} whether by email, phone, chat or SMS.
            </p>

            <br />

            <p>
              {owner} only deposits cookies if the User's browser allows it and provided that the User has continued browsing. Cookies can be placed on the User's terminal (computer, smartphone, tablet). Users have the possibility of setting their browser to refuse cookies. Blocking certain cookies may, however, disrupt the operation of the services offered.
            </p>

            <br />

            <p>
              When browsing the Webapp and/or the mobile application, the User is informed that data relating to their connection and browsing is processed by {owner}, in particular through cookies.
            </p>

            <br />

            <p>
              A cookie is a small file placed on the User's computer for a limited time when the User browses a website. Browsing information is then stored there. These cookies therefore make it possible to identify the User each time they visit a website and thus help to improve the user experience by offering them services tailored to their needs
            </p>

            <br />

            <p className="font-weight-bold">
              No advertising is submitted to our Users on our platform, so the cookies deposited are not intended to offer them any advertising on our website.
            </p>

            <br />

            <p>For more information, see our  <Anchor code='cookies'>cookies policy</Anchor>.</p>

            <br />

            <h3>Recipients</h3>

            <p>
              {owner} maintains the privacy of Users’ data and it is exclusively processed by {owner} and, unless they object, its partners.
            </p>

            <br />

            <h3>Rights of individuals over their data</h3>

            <p>
              In accordance with the legal provisions relating to the protection of personal data, the User has in particular a right of access, rectification and opposition on legitimate grounds with regard to the information concerning them and the right to organize the fate of their post-mortem data. These rights may be claimed by email sent to <a href={`mailto:${email}`}>{email}</a> specifying in the subject "MY DATA" and attaching a copy of proof of identity.
            </p>

            <br />

            <h3>Duration of data retention and access by the user to delete his data</h3>

            <p>
              The data is only kept for the time necessary for the purposes of its collection and processing.
            </p>

            <br />

            <p>
              Users may at any time unsubscribe and delete their data by accessing their Account and their data is kept for the duration of the contractual relationship. Beyond that, the data may only be kept to establish proof of a right or a contract, or in compliance with a legal obligation and in accordance with the regulations in force. The information collected by the cookies will be kept for thirteen (13) months, at the end of this period this data will be deleted.
            </p>

            <br />

            <p>
              La suppression des données de l'utilisateur peut être fait à tout moment en bas de son profil à la mention "Je souhaite supprimer mon compte et les données associées".
            </p>

            <br />

            <h3>Security and confidentiality</h3>

            <p>
              In its concern to guarantee a level of security adapted to its Users, {owner} makes every effort to guarantee the security of the data processed. For example, the Webapp and/or mobile application is hosted on servers based in France by 2ISR and does not transfer any data outside the European Union. The data remains private in accordance with declarations to the CNIL.
            </p>
          </PrivacyViewWrapper>



      }
    
    
    </>
    
  );
}

PrivacyView.propTypes = {
  infos: PropTypes.object.isRequired,
}

export default PrivacyView;
